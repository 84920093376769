<template>
  <ul>
    <template
      v-for="item in items"
    >
      <component
        :is="resolveNavItemComponent(item)"
        v-if="checkPerm(item.resource)"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    checkPerm(permission) {
      const userData = useJwt.getUserData() // достаю данные юзера из localstorage
      if (userData && userData?.permission?.includes('super_admin')) {
        //  если он super_user то показываю каждый элемент меню
        return true
      }
      if (!permission) {
        //  если нет не одного permission то скрываю элемент
        return false
      }
      for (let i = 0; i < permission.length; i += 1) {
        const element = permission[i]
        // прохожу по каждому permission'у в элементе меню
        if (element === 'all' || userData?.permission?.includes(element)) {
          //  если permission равен all или он есть в permission'ах юзера то ставлю visible true и останавливаю цикл
          return true
        }
      }
      // возвращаю видимость элемента меню
      return false
    },
  },
}
</script>
