export default [
  {
    header: 'tests.test_base',
    resource: ['content_manager', 'head_manager'],
  },
  {
    title: 'tests.tests',
    route: 'test-base',
    icon: 'SettingsIcon',
    resource: ['content_manager', 'head_manager'],
  },
]
