export default [
  {
    header: 'others.supervisor',
    resource: ['admin', 'id_manager', 'supervisor', 'head_manager'],
  },
  {
    title: 'others.class_list',
    route: 'class-list',
    icon: 'ClipboardIcon',
    resource: ['admin', 'id_manager', 'supervisor', 'head_manager'],
  },
]
