<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="GET_NOTIFICATIONS.results.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary">
          {{ GET_NOTIFICATIONS.results.length }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <!-- <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    > -->
    <!-- Account Notification -->
    <div class="scrollable-container media-list scroll-area">
      <b-link
        v-for="(notification, index) in GET_NOTIFICATIONS.results"
        :key="index"
        class="dropdown-class"
        @click="$router.push('/test-report/' + notification.object_id)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.photo"
              :text="notification.photo"
              variant="light-info"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.full_name }}
            </span>
          </p>
          <small class="notification-text">{{ notification.text }}</small>
        </b-media>
      </b-link>
    </div>
    <!-- </vue-perfect-scrollbar> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
} from "bootstrap-vue";
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from "vue-ripple-directive";
import { mapActions, mapMutations, mapGetters } from "vuex";
import useJwt from "@/auth/jwt/useJwt";
import configs from "../../axios.config";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    // VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    webSocket: null,
    notifications: [],
    perfectScrollbarSettings: {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    },
  }),
  computed: {
    ...mapGetters("courses", ["GET_NOTIFICATIONS"]),
  },
  mounted() {
    this.connect();
    this.messageListener();
    const { id } = useJwt.getUserData();
    this.FETCH_NOTIFICATIONS({ contact_id: id, page_size: 1000 });
  },
  beforeDestroy() {
    this.$socket.close()
  },
  methods: {
    ...mapMutations("courses", ["SET_SOCKET_NOTIFICATIONS"]),
    ...mapActions("courses", ["FETCH_NOTIFICATIONS"]),
    async connect() {
      // this.webSocket = new WebSocket(
      //   `${configs.notificationSocket}/?token=${useJwt.getToken()}`
      // );
      this.$connect(
        `${configs.notificationSocket}/?token=${useJwt.getToken()}`,
        {
          reconnection: true,
          reconnectionAttempts: 1,
          reconnectionDelay: 3000,
          format: "json",
        }
      );
    },
    messageListener() {
      this.$socket.onmessage = async (event) => {
        if (event.data !== "Connected...") {
          const { student, ...rest } = JSON.parse(event.data);
          console.log(this.GET_NOTIFICATIONS);
          this.SET_SOCKET_NOTIFICATIONS({ full_name: student, ...rest });
        }
      };
    },
  },
};
</script>

<style>
.dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}
</style>
