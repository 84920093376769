export default [
  {
    header: 'others.references',
    resource: ['admin', 'id_manager', 'supervisor', 'head_manager'],
  },
  // {
  //   title: 'others.login_administration',
  //   route: 'web-settings',
  //   icon: 'SettingsIcon',
  // },
  {
    title: 'others.tags',
    route: 'groups',
    icon: 'SettingsIcon',
    resource: ['admin', 'id_manager', 'supervisor', 'head_manager'],
  },
  {
    title: 'references.subjects',
    route: 'subjects-list',
    icon: '',
    resource: ['admin', 'head_manager'],
  },
]
