export default [
  {
    header: 'others.content_manager',
    resource: ['content_manager', 'admin', 'head_manager'],
  },
  {
    title: 'others.content_base',
    route: 'content-base',
    icon: 'DatabaseIcon',
    resource: ['content_manager', 'head_manager'],
  },
  {
    title: 'others.course_administration',
    route: 'course-administrations',
    icon: 'BookIcon',
    resource: ['content_manager', 'admin', 'head_manager']
  },
  {
    title: 'others.form_types',
    route: 'form-types-base',
    icon: 'BookIcon',
    resource: ['content_manager', 'admin', 'head_manager']
  },
]
