export default [
  {
    header: 'others.users',
    resource: ['id_manager', 'admin', 'supervisor', 'head_manager'],
  },
  {
    title: 'others.users_list',
    route: 'user',
    icon: 'UsersIcon',
    resource: ['admin', 'head_manager'],
  },
  {
    title: 'others.employees_list',
    route: 'employees',
    icon: 'UserIcon',
    resource: ['id_manager', 'admin', 'head_manager'],
  },
  {
    title: 'others.teachers_list',
    route: 'teachers',
    icon: 'UserIcon',
    resource: ['id_manager', 'admin', 'supervisor', 'head_manager'],
  },
  {
    title: 'others.students_list',
    route: 'pupils',
    icon: 'UserIcon',
    resource: ['id_manager', 'admin', 'supervisor', 'head_manager'],
  },
]
