<template>
  <b-nav-item @click="$router.push('/chat')">
    <feather-icon
      :badge="count"
      badge-classes="bg-danger"
      size="21"
      icon="SendIcon"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import configs from '../../axios.config'

export default {
  components: {
    BNavItem,
  },
  data() {
    return {
      count: null,
    }
  },
  watch: {
    '$route.path': {
      handler(val) {
        if (val === '/chat') {
          if (/chat-notify/gm.test(this.$socket.url)) {
            this.$socket.close()
          }
        } else {
          setTimeout(() => {
            this.connect()
          }, 100)
        }
      },
    },
  },
  beforeDestroy() {
    this.$socket.close()
  },
  mounted() {
    if (this.$route.path !== '/chat') {
      this.connect()
      this.messageListener()
    }
  },
  methods: {
    async connect() {
      await this.$connect(`${configs.webSocket}-notify/?token=${useJwt.getToken()}`, {
        reconnection: false,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000,
        format: 'json',
      })
    },
    playSound(url) {
      const audio = new Audio(url)
      audio.play()
    },
    messageListener() {
      this.$options.sockets.onmessage = async event => {
        if (event.data !== 'Connected...') {
          const { count } = JSON.parse(event.data)
          this.count = count
        // if (this.$route.path !== '/chat') {
        //   this.playSound('https://proxy.notificationsounds.com/notification-sounds/notification-tone-swift-gesture/download/file-sounds-1235-swift-gesture.mp3')
        // }
        }
      }
    },
  },
}
</script>
