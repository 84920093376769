export default [
  {
    header: 'finance.finance',
    resource: ['admin', 'head_manager'],
  },
  {
    title: 'finance.operation',
    route: 'finance',
    icon: 'TrendingUpIcon',
    resource: ['admin', 'head_manager'],
  },
  {
    title: 'finance.indecators',
    route: 'indecators',
    icon: 'BarChart2Icon',
    resource: ['admin', 'head_manager'],
  },
]
