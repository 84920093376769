<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1  d-flex"
    >
      <app-breadcrumb class="flex-grow-1" />
      <div class="nav">
        <locale
          :locales="langs.locales"
          @updateLocale="changeLocale"
        />
      </div>
      <notification
        v-if="$can('read', 'teacher') && !$can('read', 'all')"
        class="d-block"
      />
      <chat-navbar class="d-block" />
      <dark-toggler class="d-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ getUserFullname }}
            </p>
            <span class="user-status">{{ getUserRole }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="getUserPhoto"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-divider /> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('others.logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  // BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import { localize } from 'vee-validate'
import ChatNavbar from '@/components/ChatNavbar.vue'
import localeLangs from '@/utils/locales'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import ru from '@/libs/i18n/vee-validate/ru.json'
import en from '@/libs/i18n/vee-validate/en.json'
import uz from '@/libs/i18n/vee-validate/uz.json'
import Notification from '@/components/Notification.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    ChatNavbar,
    // BDropdownDivider,
    BAvatar,
    Notification,
    AppBreadcrumb,
    // Navbar Components
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // user_data: null,
      langs: localeLangs,
    }
  },
  computed: {
    getUserFullname() {
      const { first_name, full_name, username } = useJwt.getUserData().contact
      // eslint-disable-next-line no-undef
      const name = first_name || full_name || username || 'User'
      return name
    },
    getUserPhoto() {
      const user_data = useJwt.getUserData()
      return user_data?.contact.photo
    },
    getUserRole() {
      const user_data = useJwt.getUserData()
      return user_data?.role
    },
  },
  mounted() {
    const { locale } = this.$i18n
    const langs = { uz, ru, en }
    localize(locale, langs[locale])
  },
  methods: {
    logout() {
      useJwt.logout()
    },
    async changeLocale(language) {
      const { id } = useJwt.getUserData()
      const { locale } = this.$i18n
      const langs = { uz, ru, en }
      localize(locale, langs[locale])
      store
        .dispatch('users/UPDATE_USER', { id, language })
        .then(resp => {
          useJwt.setUserData(resp.data)
          this.$_successToast(this.$t('general.lang_changed'))
        })
    },
  },
}
</script>
