export default [
  {
    header: 'others.branches',
    resource: ['head_manager'],
  },
  {
    title: 'others.list_branches',
    route: 'branchesList',
    icon: 'BookIcon',
    resource: ['head_manager'],
  },
  {
    title: 'others.moderations',
    route: 'branchesModeration',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'others.branches_group',
    route: 'branchesGroups',
    icon: 'SettingsIcon',
  },
]
